@import "~antd/dist/antd.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body {
      background-color: #FDF8F8;
      font-family: "Inter", -apple-system, sans-serif;
}

a {
      color: #FBBA15;
}

a:hover {
      color: red;
}